import useCookies from '@src/hooks/useCookies';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { ROUTE_PATH } from '../constants/path';
import { AppContext } from '../context/appContext';
import '../styles/globals.css';
// import ChatbotPopup from '../components/chatbotPopup';
// import Script from 'next/script';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

// Initialize PostHog outside of the component
if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    capture_pageview: false, // Disable automatic pageview capture
  });
}

function App({ Component, pageProps }) {
  const router = useRouter();
  const [generatedExec, setGeneratedExec] = useState('');
  const [generatedSitu1, setGeneratedSitu1] = useState('');
  const [generatedSitu2, setGeneratedSitu2] = useState('');
  const [generatedMark1, setGeneratedMark1] = useState('');
  const [generatedMark2, setGeneratedMark2] = useState('');
  const [generatedMark3, setGeneratedMark3] = useState('');
  const [generatedMark4, setGeneratedMark4] = useState('');
  const [generatedOp1, setGeneratedOp1] = useState('');
  const [generatedOp2, setGeneratedOp2] = useState('');
  const [generatedMang1, setGeneratedMang1] = useState('');
  const [generatedMang2, setGeneratedMang2] = useState('');
  const [generatedFin1, setGeneratedFin1] = useState('');
  const [generatedRisk1, setGeneratedRisk1] = useState('');
  const [planPackage, setPlanPackage] = useState('');
  const [starterPrice, setStarterPrice] = useState('');
  const [proPrice, setProPrice] = useState('');
  const [productInfoPrompt, setProductInfoPrompt] = useState('');
  const [planId, setPlanId] = useState('');
  const [isPlanCompleted, setIsPlanCompleted] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [variantID, setVariantID] = useState('');
  const [experimentID, setExperimentID] = useState('');
  const { getCookie } = useCookies();

  useEffect(() => {
    if (
      router.pathname.startsWith('/form') &&
      router.pathname !== '/form/generate-result'
    ) {
      router.push(ROUTE_PATH.objective);
    }

    const ensureVariantIDIsSet = function () {
      const variantIDFromCookie = getCookie('variantID');
      const experimentIDFromCookie = getCookie('experimentID');
      setVariantID(variantIDFromCookie);
      setExperimentID(experimentIDFromCookie);
      if (variantIDFromCookie) {
        const chatbotAutoSuggestionLeft = localStorage.getItem(
          'chatbotAutoSuggestionLeft',
        );
        const chatbotAutoSuggestionLeftInt = chatbotAutoSuggestionLeft
          ? parseInt(chatbotAutoSuggestionLeft)
          : 3;

        if (!chatbotAutoSuggestionLeft) {
          localStorage.setItem('chatbotAutoSuggestionLeft', '3');
        }

        const handleMessage = (event) => {
          if (event.data.type === 'UI.OPENED') {
            setIsChatOpen(false);
            clearTimeout(timer);
          }
        };

        window.addEventListener('message', handleMessage);

        const timer = setTimeout(() => {
          if (chatbotAutoSuggestionLeftInt > 0) {
            setIsChatOpen(true);
            localStorage.setItem(
              'chatbotAutoSuggestionLeft',
              (chatbotAutoSuggestionLeftInt - 1).toString(),
            );
          }
        }, 60000);

        return () => {
          window.removeEventListener('message', handleMessage);
          clearTimeout(timer);
        };
      } else {
        setTimeout(ensureVariantIDIsSet, 0);
      }
    };

    ensureVariantIDIsSet();

    // PostHog is already initialized outside; no need to initialize here
  }, []);

  console.log({ message: '_app.tsx: Cookies', variantID, experimentID });

  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="favicon/site.webmanifest" />
        <link rel="preload" as="image" href="/img/thumbnail-v2.png" />
        <link
          rel="mask-icon"
          href="favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>

      <PostHogProvider client={posthog}>
        <SessionProvider session={pageProps.session}>
          <GoogleAnalytics trackPageViews nonce="rAnD0m1z5" />
          <AppContext.Provider
            value={{
              get: {
                generatedExec,
                generatedSitu1,
                generatedSitu2,
                generatedMark1,
                generatedMark2,
                generatedMark3,
                generatedMark4,
                generatedOp1,
                generatedOp2,
                generatedMang1,
                generatedMang2,
                generatedFin1,
                generatedRisk1,
                planPackage,
                starterPrice,
                proPrice,
                productInfoPrompt,
              },
              set: {
                setGeneratedExec,
                setGeneratedSitu1,
                setGeneratedSitu2,
                setGeneratedMark1,
                setGeneratedMark2,
                setGeneratedMark3,
                setGeneratedMark4,
                setGeneratedOp1,
                setGeneratedOp2,
                setGeneratedMang1,
                setGeneratedMang2,
                setGeneratedFin1,
                setGeneratedRisk1,
                setPlanPackage,
                setStarterPrice,
                setProPrice,
                setProductInfoPrompt,
              },
              planId,
              setPlanId,
              isPlanCompleted,
              setIsPlanCompleted,
            }}
          >
            <div className="h-dvh">
              <Script
                src="https://mediafiles.botpress.cloud/9da8863b-59de-4f58-84bd-b1a54559e57f/webchat/v2.1/config.js"
                strategy="afterInteractive"
                onError={(e: Error) => {
                  console.error('Mediafiles Botpress failed to load', e);
                }}
              />
            </div>

            <Component {...pageProps} />
          </AppContext.Provider>
        </SessionProvider>
      </PostHogProvider>
    </>
  );
}

export default appWithTranslation(App);
